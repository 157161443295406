import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Page from '../../components/Page';
import LogosBlock from '../../components/Logos/block';
import Location from '../../components/Location';

import styles from './case.module.css';

const CasePage = ({ data, params }) => {
  const { url } = params;
  const caseData = data.allCasesJson.edges
    .map((x) => x.node)
    .find((x) => x.url === url);
  return (
    <div>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: 'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
          },
          {
            name: 'keywords',
            content:
              'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
          },
        ]}
      >
        <html lang="sv" />
        <link rel="icon" href="/favicon.ico?v=2" />
      </Helmet>
      <div className={styles.content}>
        <Header className={styles.headerBackground} />
        <Page bgColor="#FFFCF7">
          <section>
            <h1 style={{ color: '#F7A416' }} className={styles.title}>
              {caseData.title}
            </h1>
            <h2>{caseData.header}</h2>
            <p>{caseData.subheader}</p>
          </section>
          <main>
            <div className={styles.illustration}>
              <img src={`/images/${caseData.image}`} />
            </div>
            <div className={styles.line}></div>
            {caseData.blocks.map((block, i) => (
              <section key={i} className={styles.section}>
                <div className={styles.sectionText}>
                  <h3>{block.title}</h3>
                  <p>{block.text}</p>
                </div>
              </section>
            ))}
          </main>
        </Page>
        <LogosBlock
          id="logos"
          logos={data.allLogosJson.edges.map((x) => x.node)}
        />
        <Location title={data.site.siteMetadata.title} id="location" />
        <Footer
          products={data.allProductsJson.edges.map((x) => x.node)}
          cases={data.allCasesJson.edges.map((x) => x.node)}
          title={data.site.siteMetadata.title}
        />
      </div>
    </div>
  );
};

export default CasePage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allLogosJson {
      edges {
        node {
          src
          alt
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          shortTitle
          title
          header
          subheader
          image
          blocks {
            title
            text
          }
        }
      }
    }
  }
`;
